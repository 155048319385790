import queryString from "query-string";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestPopulateDTO from "dto/app/requestpopulate.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MenuDomDto } from "components/elements/menu/MenuDom";
import { Config } from "./config";
import { ICurrentRoute } from "interfaces/currentroute.interface";

class RouteTools {
  static _LL: any = false;
  static hasAccess: any = false;
  static checkUser: any = false;

  static setLL(_l: any): any {
    RouteTools._LL = _l;
  }

  static setHasAccess(_h: any): any {
    RouteTools.hasAccess = _h;
  }

  static setCheckUser(_h: any): any {
    RouteTools.checkUser = _h;
  }

  static checkRoutePermission(route: string): boolean {
    if (!RouteTools.hasAccess) return false;
    return RouteTools.hasAccess(route);
  }
  // -----------------------------------------
  static processSpecialModule(module: string): string {
    if (module === "language") return "lang";
    if (module === "role") return "roles";
    return module;
  }
  static getPmHash(m: string): string {
    let t = "api.resource.";

    t += RouteTools.processSpecialModule(m);

    return t;
  }

  static getPmHash_List(m: string): string {
    const h = "get-" + RouteTools.getPmHash(m);
    return h + ".index";
  }

  static getPmHash_Add(m: string): string {
    const h = "post-" + RouteTools.getPmHash(m);
    return h + ".store";
  }

  static getPmHash_Update(m: string): string {
    const h = "put-" + RouteTools.getPmHash(m);
    return h + ".update";
  }

  static getPmHash_Delete(m: string): string {
    const h = "delete-" + RouteTools.getPmHash(m);
    return h + ".destroy";
  }

  static getPmHash_Special(
    m: string,
    specialType: string,
    action: string
  ): string {
    if (action === "add") {
      return RouteTools.getPmHash_Add(specialType);
    }
    if (action === "edit") {
      return RouteTools.getPmHash_Update(specialType);
    }
    if (!action) {
      return RouteTools.getPmHash_List(specialType);
    } else {
      return RouteTools.getPmHash_Get(specialType);
    }
  }

  static getPmHash_Get(m: string): string {
    const h = "get-" + RouteTools.getPmHash(m);
    return h + ".show";
  }

  static specialController = [
    "faqrelation",
    "faqanswer",
    "offer",
    "gallery",
    "attachment",
    "video",
    "productfilter",
    "deliverymethodoffer",
    "orderjournal",
    "orderproduct",
    "productwishlist",
    "aclroles",
  ];

  static thirdLevelSpecialController = ["offerfilter", "orderproductfilter"];

  static modules = [
    "user",
    "language",
    "label",
    "config",
    "role",

    "page",
    "attachment",
    "country",
    "currency",
    "socialpage",
    "navigator",
    "faq",
    "typelocation",
    "blog",
    "categoryblog",
    "categoryproduct",
    "filter",
    "filterdictionar",
    "advertisement",
    "product",
    "location",
    "paymethod",
    "deliverymethod",
    "deliverymethodlocation",
    "order",
    "configfile",
    "fromemail",
    "emailtemplate",
    "smstemplate",
    "notification",
  ];

  static getAdminLeftMenu_Childs(m: string[]): MenuDomDto[] {
    var children: MenuDomDto[] = [];

    m.forEach((item) => {
      const ph = RouteTools.getPmHash_List(item);
      if (!ph) return false;

      const obj: MenuDomDto = new MenuDomDto();
      obj.identifier = "am_" + item;
      obj.children = false;
      obj.href = "/" + item;
      obj.label = RouteTools._LL("AM_" + item);
      obj.permissionhash = ph;
      if (RouteTools.checkRoutePermission(ph)) children.push(obj);
    });

    return children;
  }

  static getAdminLeftMenu_Group(
    mm: string,
    childsModule: string[]
  ): MenuDomDto | boolean {
    var children: MenuDomDto[] =
      RouteTools.getAdminLeftMenu_Childs(childsModule);
    if (!children.length) return false;

    var rez: MenuDomDto = new MenuDomDto();
    rez.identifier = "amg_" + mm;
    rez.children = children;
    rez.href = false;
    rez.label = RouteTools._LL("AMG_" + mm);
    rez.icon = "amg_" + mm;

    return rez;
  }

  static getAdminLeftMenu_UserGroup(): MenuDomDto | boolean {
    var childsModule = ["user", "role"];
    return RouteTools.getAdminLeftMenu_Group("user", childsModule);
  }

  static getAdminLeftMenu_StaticGroup(): any {
    var childsModule = [
      "language",
      "label",
      "config",
      "configfile",
      "page",
      "socialpage",
      "navigator",
      "faq",
      "blog",
      "categoryblog",
      "advertisement",
      "location",
      "paymethod",
    ];
    return RouteTools.getAdminLeftMenu_Group("static", childsModule);
  }

  static getAdminLeftMenu_NomenclatureGroup(): any {
    var childsModule = ["country", "currency", "typelocation"];
    return RouteTools.getAdminLeftMenu_Group("nomenclature", childsModule);
  }

  static getAdminLeftMenu_NotificationGroup(): any {
    var childsModule = [
      "fromemail",
      "emailtemplate",
      "smstemplate",
      "notification",
    ];
    return RouteTools.getAdminLeftMenu_Group("notification", childsModule);
  }

  static getAdminLeftMenu_ProductGroup(): MenuDomDto | boolean {
    var childsModule = [
      "categoryproduct",
      "filter",
      "filterdictionar",
      "product",
    ];
    return RouteTools.getAdminLeftMenu_Group("product", childsModule);
  }

  static getAdminLeftMenu_DeliveryGroup(): MenuDomDto | boolean {
    var childsModule = ["deliverymethod", "deliverymethodlocation"];
    return RouteTools.getAdminLeftMenu_Group("delivery", childsModule);
  }

  static getAdminLeftMenu_SaleGroup(): MenuDomDto | boolean {
    var childsModule = ["order"];
    return RouteTools.getAdminLeftMenu_Group("sale", childsModule);
  }

  static getAdminLeftMenu(): any {
    let rez = [];
    let t: any = false;

    t = RouteTools.getAdminLeftMenu_UserGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_StaticGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_NomenclatureGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_ProductGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_DeliveryGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_SaleGroup();
    if (t) rez.push(t);

    t = RouteTools.getAdminLeftMenu_NotificationGroup();
    if (t) rez.push(t);

    return rez;
  }

  // -----------------------------------------
  static processSpecial(paths: any): any {
    if (!paths || !paths.length) {
      // home Page
      return { mobj: "homepage", permissionhash: "/" };
    }

    const m = paths[0];

    if (m === "login") {
      return { mobj: "login", permissionhash: "post-auth-signin" };
    }

    if (m === "signup") {
      return { mobj: "signup", permissionhash: "post-auth-signup" };
    }

    if (m === "profile") {
      return { mobj: "profile", permissionhash: "get-user-get" };
    }

    if (m === "forbidden") {
      return { mobj: "forbidden", permissionhash: "" };
    }

    return false;
  }

  static processModuleItem(paths: any, module: string): any {
    if (!paths || !paths.length) {
      return false;
    }

    const m: any = paths[0];

    if (m !== module) {
      return false;
    }

    if (paths.length === 1) {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_List(module),
      };
    }

    const mSecondPath: string = paths[1];
    const mThirdPath: string = paths[2] != undefined ? paths[2] : "";
    const mFourthPath: string = paths[3] != undefined ? paths[3] : "";
    const fifthPath: string = paths[4] != undefined ? paths[4] : "";
    const sixthPath: string = paths[5] != undefined ? paths[5] : "";

    if (mSecondPath === "add") {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_Add(module),
      };
    }

    if (mSecondPath === "edit") {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_Update(module),
      };
    }

    if (RouteTools.thirdLevelSpecialController.includes(fifthPath)) {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_Special(
          module,
          fifthPath,
          sixthPath
        ),
      };
    }

    if (RouteTools.specialController.includes(mThirdPath)) {
      return {
        mobj: module,
        permissionhash: RouteTools.getPmHash_Special(
          module,
          mThirdPath,
          mFourthPath
        ),
      };
    }

    return {
      mobj: module,
      permissionhash: RouteTools.getPmHash_Get(module),
    };
  }

  static processModule(paths: any): any {
    if (!paths || !paths.length) {
      return false;
    }

    var rez = false;

    RouteTools.modules.forEach((item) => {
      if (rez) return;
      const t = RouteTools.processModuleItem(paths, item);
      if (t) rez = t;
    });

    return rez;
  }

  //---------------------------------------

  static processRoute(
    paths: any,
    parsed: any,
    fullUrl: any,
    anchor: string
  ): any {
    var mo: any = false;

    mo = RouteTools.processSpecial(paths);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        mo.permissionhash,
        anchor
      );
    mo = RouteTools.processModule(paths);
    if (mo)
      return RouteTools.processRouteVars(
        mo.mobj,
        paths,
        parsed,
        fullUrl,
        mo.permissionhash,
        anchor
      );

    return RouteTools.processRouteVars(
      "notfound",
      paths,
      parsed,
      fullUrl,
      "/",
      anchor
    );
  }

  // -----------------------------------------
  static processRouteVars(
    mainObj: any,
    paths: any,
    parsed: any,
    fullUrl: any,
    permissionhash: any,
    anchor: string
  ): ICurrentRoute {
    if (!anchor) anchor = "";

    if (!RouteTools.checkRoutePermission(permissionhash)) {
      mainObj = "forbidden";
    }
    if (!RouteTools.checkUser()) {
      RouteTools.setHistory("/login", {});
      mainObj = "login";
    } else if (mainObj === "login" || mainObj === "signup") {
      RouteTools.setHistory("/", {});
      mainObj = "homepage";
    }

    return {
      _mainobject: mainObj,
      _paths: paths,
      _getVars: parsed,
      url: fullUrl,
      anchor: anchor,
    };
  }

  // -----------------------------------------
  // -----------------------------------------
  // -----------------------------------------

  // -----------------------------------------
  static getDefaultIdParent(currentRoute: any, cookies: any): string {
    const _reqList = RouteTools.prepareListRequest(currentRoute, cookies);

    let _idParent = "";

    if (_reqList && _reqList.filters && _reqList.filters.length) {
      _reqList.filters.forEach((item, index) => {
        if (item.field != "idparent") return;
        if (!item.values || !item.values.length) return;
        _idParent = item.values[0];
      });
    }

    return _idParent;
  }

  // -----------------------------------------
  static prepareListRequest(currentRoute: any, cookies: any): RequestListDTO {
    const cOnPage =
      cookies.onPage != undefined ? Number(cookies.onPage) : Config.ON_PAGE;

    const obj = new RequestListDTO();
    const _getVars =
      currentRoute && currentRoute._getVars ? currentRoute._getVars : {};

    obj.page = _getVars.page ? Number(_getVars.page) : 1;
    obj.onpage = _getVars.onpage ? Number(_getVars.onpage) : cOnPage;

    obj.filters = [];
    if (_getVars.filters != null && _getVars.filters != undefined) {
      const filters = _getVars.filters.split("|");

      for (let i in filters) {
        const t = filters[i].split(",");

        const _o = new RequestFilterDTO();
        _o.field = t[0].trim();
        t.shift();
        _o.values = t;

        if (!_o.field) continue;
        if (_o.values == undefined || !_o.values.length) continue;

        obj.filters.push(_o);
      }
    }

    if (_getVars.populates != null && _getVars.populates != undefined) {
      const _o = new RequestPopulateDTO();
      _o.populates = _getVars.populates.split(",");
      obj.populate = _o;
    }

    obj.sortcriteria = [];

    if (_getVars.order != null && _getVars.order != undefined) {
      const order = _getVars.order.split("|");

      for (let i in order) {
        const t = order[i].split(",");

        const _o = new RequestSortCriteriaDTO();
        _o.field = t[0].trim();
        _o.asc = t.length > 1 && t[1] == "asc" ? true : false;

        if (!_o.field) continue;

        obj.sortcriteria.push(_o);
      }
    }

    return obj;
  }

  // -----------------------------------------
  static prepareListLocation(obj: RequestListDTO): string {
    let str = "";

    if (obj.page) {
      str += str ? "&" : "?";
      str += "page=" + obj.page.toString();
    }

    if (obj.onpage) {
      str += str ? "&" : "?";
      str += "onpage=" + obj.onpage.toString();
    }

    if (obj.filters && obj.filters.length) {
      let fstr = "";

      for (let i in obj.filters) {
        if (!obj.filters[i].values) continue;
        if (!Array.isArray(obj.filters[i].values)) continue;
        if (!obj.filters[i].values?.length) continue;

        fstr += fstr ? "|" : "";
        fstr += obj.filters[i]?.field;
        fstr += ",";
        fstr += obj.filters[i].values?.join(",");
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "filters=" + fstr;
      }
    }
    // logger("getDefaultRequestList", obj, obj.sortcriteria);
    if (obj.sortcriteria && obj.sortcriteria.length) {
      let fstr = "";

      for (let i in obj.sortcriteria) {
        fstr += fstr ? "|" : "";
        fstr += obj.sortcriteria[i]?.field;
        fstr += ",";
        fstr += obj.sortcriteria[i]?.asc ? "asc" : "desc";
      }

      if (fstr) {
        str += str ? "&" : "?";
        str += "order=" + fstr;
      }
    }

    if (
      obj.populate &&
      obj.populate.populates &&
      obj.populate.populates.length
    ) {
      str += str ? "&" : "?";
      str += "populates=" + obj.populate.populates.join(",");
    }

    return str;
  }

  static parseLocation(location: any): any {
    const parsed = queryString.parse(location.search);

    const fullUrl = location.pathname + location.search;

    var tpaths = location.pathname.split("/");
    tpaths = tpaths.filter(Boolean);

    let anchor = location.hash.replace("#", "");
    if (!anchor) anchor = "";

    return RouteTools.processRoute(tpaths, parsed, fullUrl, anchor);
  }

  // -----------------------------------------
  static setHistory(location: any, state: any, anchor?: string): any {
    state = state ? state : {};
    if (anchor) location += "#" + anchor;
    window.history.pushState(state, "", location);

    var popStateEvent = new PopStateEvent("popstate", state);
    dispatchEvent(popStateEvent);
  }

  static setAnchor(anchor: string): void {
    window.location.hash = anchor;
  }
  // -----------------------------------------
}

export { RouteTools };
